import React from 'react';
import DashboardIcon from '@mui/icons-material/Dashboard';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import LogoutIcon from '@mui/icons-material/Logout';
import './Sidebar.scss';
import { useNavigate } from 'react-router-dom';

const Sidebar=()=>{

  const navigate=useNavigate();
  const logout=()=>{
    localStorage.removeItem("token");
    navigate("/login");
  }

    return(
        <>
         <div className="left">
            <h3>Data Gathering</h3>
            <hr />
            <ul>
                <li>
                  <a href='/dashboard/home'><DashboardIcon className='icon'/>
                    <span>Dashboard</span>
                  </a>
                </li>
                <li>
                  <a href='/dashboard/uploadText'><UploadFileIcon className='icon'/>
                  <span>Upload text</span>
                  </a>
                </li>
                <li>
                  <a onClick={logout}><LogoutIcon className='icon'/>
                  <span>Logout</span>
                  </a>
                </li>
            </ul>
        </div>
        </>
    )
}

export default Sidebar;