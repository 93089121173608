import React from 'react'

const Stats = () => {
  return (
    <div className="right">
     <h5>Audio information</h5>
    </div>     
  )
}

export default Stats;
