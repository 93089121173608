import React from 'react';

const Error=()=>{
    return(
        <>
        <h5>Sorry, Page not found</h5>
        </>
    )
}

export default Error;