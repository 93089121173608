import React, {useState } from 'react'
import { useNavigate } from 'react-router-dom';
import './login.scss';

const Login = () => {
  const[username,setUsername]=useState("")
  const[password,setPassword]=useState("")
  const navigate =useNavigate();

  const onClick=async (e)=>{
    console.log("on click")
    e.preventDefault()
    
    let result =await fetch("https://backend.aiopenvalley.com/auth/login",{
      credentials:'include',
      method:'post',
      body:JSON.stringify({username,password}),
      headers:{
        'content-Type':'application/json'
      }  
    });
    result= await result.json();
    console.warn(result.token)

    if(result.user)
    {
      localStorage.setItem('user',JSON.stringify(result))
      localStorage.setItem('token', result.token);
      navigate('/dashboard/home');
      window.location.reload();
    }
    else{
      console.log("errors")
      alert('please enter valid data')
    }
  }

  return (
    <div className='login'>
        <h1>Welcome To Data Gathering </h1>
        <h3>Admin panel</h3>
        <div className="card">
        <form onSubmit={onClick}>
          <input type="text" placeholder='enter username' value={username}
            onChange={(e)=>setUsername(e.target.value)}
          />
          <input type="password" placeholder='enter password' value={password}
            onChange={(e)=>setPassword(e.target.value)}/>
          <button type='submit'>Login</button>
        </form>
        </div>         
    </div>
  )
}

export default Login;
