import React from 'react';
import List from '../table/Table';
import './dashboard.scss';
// import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import DownloadIcon from '@mui/icons-material/Download';
import axios from 'axios'
// import { saveAs } from "file-saver";

// import Axios from 'axios'
// import FileDownload from "js-file-download";
// import fileDownload from 'js-file-download';

const Dashboard = () => {
  const download = () =>{
    // e.preventDefault()
    axios.get('https://backend.aiopenvalley.com/downloadaudio',{
      withCredentials:true,
      responseType: 'arraybuffer',
      headers: {
          'Content-Type': 'multipart/form-data',
      }
  }).then((obj)=>{
    let blob = new Blob([obj], { type: 'application/zip' })

    const downloadUrl = URL.createObjectURL(blob)
    let a = document.createElement("a"); 
    a.href = downloadUrl;
    a.download ='audio.zip' ;
    document.body.appendChild(a);
    a.click();
    
    }) 
    }
    
  return (
        <div className="right">
          <div className='navbar'>
              <h5>Recorded audio lists</h5>
              {/* <div className="search">
                <input type="text" placeholder="Search by text"/>
                <SearchOutlinedIcon />
              </div> */}
              {/* <button className='button-style'>
                <p style={{display:'inline'}}>Download all confirmed audio</p>
                <DownloadIcon style={{display:'inline'}}/>
              </button>     */}
          </div>
          <List/>
          {/* <div>
            <button className='button-style' onClick={download}>
              <p style={{display:'inline'}}>Download all confirmed audio</p>
              <DownloadIcon style={{display:'inline'}}/>
            </button>
          </div> */}
        </div>     
  )
}

export default Dashboard;
