import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import React, {useState} from 'react';
import axios from 'axios';
import './upload.scss';
const Upload = () => {
  const [file, setFile] = useState()

  function handleChange(event) {
    setFile(event.target.files[0])
  }
  
  function handleSubmit(event) {
    //event.preventDefault()
    const url = 'https://backend.aiopenvalley.com/uploadtext';
    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileName', file.name);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    axios.post(url, formData, config,{withCredentials:true}).then((response) => {
      console.log(response.data);
    });

  }
  return (
    <div className="right">
    <form className='form' onSubmit={handleSubmit}>
          <h5>Upload Text Lables</h5>
          <label htmlFor='file'><DriveFolderUploadIcon className='icon'/> </label>
          <input type="file" id='file'  onChange={handleChange}/>
          <p>Upload json file</p>
         <div className='button'>
          <button type="submit">Save</button>
          <button type="submit">Cancel</button>
         </div>    
        </form>
    </div>   
  )
}

export default Upload;
