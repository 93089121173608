import { useEffect } from "react"
import { useNavigate,Outlet } from 'react-router-dom';
import Sidebar from '../Sidebar/Sidebar';
import './dashboardNav.scss';

export default function DashboardNavigator(){
    const navigate =useNavigate();
    useEffect(()=>{
        let user =localStorage.getItem("user")
        if(!user){
            navigate("/login")
        }
    },[])
    return (
        <>
        <div className="dashboardNav">
            <Sidebar className='left'/>
            <Outlet className='right'/>
        </div>
        </>
    )
}