import Dashboard from './dashboard/Dashboard';
import React from 'react';
import {Route,Routes} from 'react-router-dom';
import Upload from './Upload/Upload';
import Stats from './Stats/Stats';
import Login from './auth/Login';
import LandingPage from './component/LandingPage';
import Error from './component/Error';
import DashboardNavigator from './Navigation/dashboardNavigator';

const App=()=> {
  return (
        <Routes>
            <Route  path='/' element={<LandingPage/>}/>
            <Route path='/login' element={<Login/>}/>
            <Route path='/dashboard' element={<DashboardNavigator/>}>
                  <Route path='home' element={<Dashboard/>}/>
                  <Route path='uploadText' element={<Upload/>}/>
                  <Route path='stats' element={<Stats/>}/> 
            </Route>
            <Route path="*" element={<Error/>}></Route>
          </Routes>
  );
}

export default App;