import React, {  useEffect,useState } from 'react';
import './table.scss';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios from 'axios';

let token =localStorage.getItem('token')

const config = {
  headers: { Authorization: `Bearer ${token}` }
};

  const List = () => {

    const [name , setName] = useState([]);
    const [count,setCount]=useState(0);

    useEffect(()=>{
      async function getdata(){
        const res =await axios.get('https://backend.aiopenvalley.com/audio',config,{withCredentials:true});
        setName(res.data)
      }
      getdata()
    },[])

    const increase = () => {
      setCount(prevCount => {
        const newCount = Number(prevCount) + 1;
        localStorage.setItem("count", newCount);
        return newCount;
      });
    };
   
    useEffect(() => {
      const initialValue = localStorage.getItem("count");
      if (initialValue) setCount(initialValue);
    }, []);


    function addKeyValue(obj, key, data, keys, id){
      obj[key] = data;
      obj[keys]=id;
    }
     name.map(person=> {
      return addKeyValue(person, 'action1', 'Listen','action3',person._id) ||
      addKeyValue(person, 'action2', 'Delete','action4',person._id)
    });

    let len = name.length
    const del2=(e)=>{
      increase();
      console.log(e.target.id)
      axios.delete('https://backend.aiopenvalley.com/audio/'+e.target.id,config,{withCredentials:true}).then((err,obj)=>{})
      const thisClicked = e.currentTarget
      thisClicked.innerText = 'Deleting'
      window.location.reload()
    }

    useEffect(() => {
      setCount(JSON.parse(window.localStorage.getItem('count')));
    }, []);

    return (
      <div className="table">
        <div className='stats'>
          <h4 >Daily Audio Recieved :  {len} </h4>
          <h4>Daily Deleted Audios :  {count}</h4>
       </div>
      <TableContainer component={Paper} >
        <Table >
          <TableHead>
            <TableRow>
              <TableCell className="tableCell">Text</TableCell>
              <TableCell className="tableCell">Listen</TableCell>
              <TableCell className="tableCell">Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className='tablebody'>
            {name && name.map((row) => (
              <TableRow key={row.sn}>
                <TableCell className="tableCell">{row.text}</TableCell>
                <TableCell className="tableCell"><a href={`https://backend.aiopenvalley.com/static/${row.audio}`} target="_blank"> <button className='listen'>{row.action1}</button> </a></TableCell>
                <TableCell className="tableCell"> <button id={row.action4} className='delete' onClick={del2}>{row.action2}</button></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      </div>
      
    )
  }
  
  export default List;